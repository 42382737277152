  <template>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-11 col-xl-9">
        <!-- Pre-aprobado Card -->
        <div class="pre-approved-card text-center">
          <h1 class="title">¿Has conectado todos tus bancos activos?</h1>
          <p class="subtitle"> 
            Por favor, asegúrate de haber conectado todas las cuentas donde recibes ingresos y pagas tus gastos. <br class="d-none d-md-block"><br class="d-none d-md-block">Esto nos ayuda a verificar si el préstamo es asequible para ti y agiliza el proceso de aprobación.
          </p>
          
          <h2>Cuentas Conectadas</h2>

          <div class="banks">
            <img v-for="bank in banks" :src="bank.url" :key="bank.id">
          </div>

          <div class="group-button" v-if="preload">
            <button class="btn-continue btn-continue--secondary" @click="connectAnotherAccount">
              Conectar otra cuenta
            </button>
            <button :class="banks.length > 0 ? 'btn-continue' : 'btn-continue btn-continue--grey'"  @click="updateRequestStatus(banks)">
              Continuar
            </button>
          </div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta'
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex'
  import axios from 'axios'
  import { useRouter } from 'vue-router'
  export default {
    name: 'StepTwo',
    emits: ['next', 'back', 'refreshToken'],
    components: {
    },
    data() {
      return {
        banks: [],
        preload: false
      }
    },
    setup(props, { emit }) {
      const store = useStore();
      const router = useRouter();
      // creamos un boolean para limitar el numero de veces que se hace la llamada axios
      // ya que generaba conflicto y generar multiples solicitudes en fintern
      const boton_pulsado  = ref(false);
  
      useMeta({
        title: 'Paso 2 - Ibancredit',
        meta: [
          { name: 'robots', content: store.state.dominio == 'ibancar.com' ? 'index' : 'noindex' },
          { name: 'description', content: 'Finaliza tu solicitud de préstamo pre-aprobado con Ibancredit' }
        ]
      })

      const sessionPreAprobado = computed(() => store.state.sessionPreAprobado);

      const nextStep = () => emit('next');
      const previousStep = () => emit('back');
      const connectAnotherAccount = () => router.push('/pre-aprobado/escoger-banco');

      const updateRequestStatus = (banks) => {

        if(banks.length > 0){
          // controlamos que solo se mande la primera vez          
          if(!boton_pulsado.value){
            try {
              axios.post(`${process.env.VUE_APP_AXIOS_BASE_URL}ibancredit/continuar/solicitud/${sessionPreAprobado.value.id_solicitud} `)
              .then(() => nextStep())
              .catch(error => {
                console.error('Error updating request status:', error);
              });
            } catch (error) {
              console.error('Error updating request status:', error);
            }
          }
          boton_pulsado.value = true;

        } else {
          alert("Se necesita tener un banco registrado.");
        }
      }

      return {
        sessionPreAprobado,
        nextStep,
        previousStep,
        connectAnotherAccount,
        updateRequestStatus
      }
    },
    
    async mounted() {
      try {
        await this.$emit('refreshToken');
        await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds to update the session before getting the banks
        
        this.banks = this.sessionPreAprobado.enlaces_svg.map((bank, index) => ({
          id: index++,
          url: bank
        }));
      } catch (error) {
        console.error('Error refreshing token:', error);
      }

      this.preload = true;
    }
  }
  </script>

  <style scoped lang="scss">
    @import "@/assets/styles/variables.scss";

    h2{
      text-align: left;
      font-size: 1.12rem;
      color: $blue;
      margin-bottom: 0;
      font-weight: 700;
    }

    .title{
      display: block;
      font-size: 1.57rem;
      text-align: left;
      line-height: normal;
      color: $blue;
      margin-bottom: 1rem;
      font-weight: bolder;
    }

    .subtitle {
      color: $blue;
      font-size: 0.813rem;
      text-align: left;
      margin-bottom: 2rem;
      font-weight: 500;
      line-height: normal;
    }

    .pre-approved-card {
      background: white;
      border-radius: 12px;
      padding: 2rem;
      //margin: 1rem;
      margin-top: 4rem;
      text-align: left;
    }

    .btn-continue.btn-continue--secondary{
      background: #fff;
      color: $orange;
      border: 1px solid $orange;
      padding: 0.65rem 1rem;
    }

    .btn-continue.btn-continue--grey{
      background: #ccc;
      padding: 0.65rem 1rem;
    }

    .btn-continue {
      background: $orange;
      color: white;
      border: none;
      padding: 0.65rem 2.5rem;
      font-size: 1.38rem;
      font-weight: 700;
      width: 100%;
      margin-bottom: 1.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
      border-radius: 30px;
      box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .banks{
      display: flex;
      flex-direction: row;
      justify-content: left;
      gap: 1rem;
      margin: 1rem 0 3rem 0;
      img{
        max-width: 60px;
      }
    }

    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";

    @include media-breakpoint-only(lg) {
      .title{
        font-size: 2.6rem !important;
        text-align: center;
      }
    }

    @include media-breakpoint-up(lg) {
      .title{
        font-size: 3rem;
        margin-bottom: 1.4rem;
      }
      .subtitle{
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 3rem;
      }
      h2{
        font-size:1.9rem;
      }
      .btn-continue{
        max-width: 560px;
        font-size: 2rem;
        margin-top:3rem;
        justify-self: center;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .btn-continue{
        margin-bottom: 5rem;
      }

      .group-button{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
      }

      .banks{
        margin-bottom: 0;
        img{
          min-width: 100px;
        }
      }

      .pre-approved-card {
        padding: 2rem 0;
      }  
      
    }
  </style>
